.navbar{
   display: flex;
   text-align: right;
   align-items: center;
   font-size: 2vw;
   padding: 30px;
   border-bottom: 5px solid darkolivegreen;
   background-color: white;
   position: fixed;
   width: 100%;
   right: 0;
   z-index: 2;
}

.nav-links {
    float: right;
}

.nav-item, .nav-item-resume{
    margin-top: 30px;
    margin-left: 30px;
    width: 30px;
    font-family: 'Montserrat', sans-serif;
}

.nav-item#home {
    color: darkolivegreen;
    font-weight: 1000;
}

.nav-item:link {
    text-decoration: none;
    color: #787877;
}

.nav-item-resume {
    text-decoration: none;
    color: #787877;
    /* box-shadow: 2px 2px 2px 1px darkolivegreen; */
    padding: 4px;
}

.nav-item:visited, .nav-item-resume:visited {
    text-decoration: none;
    color: #787877;
}

.nav-item:hover, .nav-item-resume:hover {
    color: darkolivegreen;
    padding: 5px;
    outline: 4px solid darkolivegreen;
}

.logo {
    height: 5vw;
    float: left;
}