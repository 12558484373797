.contact-container{
    background-image: none;
    display: flex;
    justify-content: center;
    width: 100%;
    /* height: 6000px; */
    /* height: 900px;; */
}

.contact-page-title{
    top: 30%;
    font-family: 'Merriweather', serif;
    position: absolute;
    color: white;
    font-size: 8vw;
    text-shadow: 2px 2px 4px #000000;
}

.contact-page-title-line {
    border: 5px solid darkorange;
    width: 75%;
    box-shadow: 2px 2px 4px #000000 !important;
}

.contact-card {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    left: 0;
    top: 45%;
    width: 80%;
    height: 50%;
    background-color: slategray;
    margin-left: 10%;
    opacity: 0.7;
    box-shadow: 3px 3px 8px #000000;
    border: 4px solid orange;
    border-radius: 20px;
    text-align: right;
}



.contact-name {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    font-family: 'Merriweather', serif;
    top: 35%;
    left: 30%;
    font-size: 6vw;
    /* margin-left: 10%; */
    text-shadow: 2px 2px 4px black;
}

.contact-name-line {
    border: 2px solid darkolivegreen;
    width: 70%;
    left: 30%;
    box-shadow: 2px 2px 4px #000000 !important;
    position: absolute;
    top: 60%;
    opacity: .5;
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    justify-self: right;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    top: 70%;
}

.contact-email {
    position: relative;
    font-size: 4vw;
    /* margin-top: 100px; */
    /* margin-left: 0px; */
    margin-right: 60px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    color: white;
    font-style: italic;
    text-shadow: 2px 2px 4px #000000;
}

.contact-phone {
    position: relative;
    font-size: 4vw;
    /* margin-bottom: 100px; */
    width: 100%;
    color: white;
    margin-left: -10%;
    font-style: italic;
    text-shadow: 2px 2px 4px #000000;
}

.about-phone:link {
    text-decoration: none;
    color: white;
    margin-left: 5px;
}

.about-email:link {
    text-decoration: none;
    color: white;
    margin-left: 5px;
}

.contact-phone:hover, .about-phone:hover {
    color: orange;
}

.contact-email:hover, .about-email:hover {
    color: orange;
}

.tree-container {
    height: 115%;
}

.tree {
    width: auto;
    height: 100%;
    position: relative;
    left: -40%;
    top: -13%;
}