.portfolio-container {
    display: flex;
    justify-content: center;
}

.portfolio-page-title{
    top: 30%;
    font-family: 'Merriweather', serif;
    position: absolute;
    color: white;
    font-size: 8vw;
    text-shadow: 2px 2px 4px #000000;

    /* width: 100%; */
}

.portfolio-page-title-line {
    border: 5px solid darkorange;
    width: 75%;
    box-shadow: 2px 2px 4px #000000 !important;

}

.filter {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 50%;
    width: 100%;
    color: darkorange;
    text-shadow: 1px 1px 2px #000000;

}

.category-filter {
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5vw;
}

.technology-filter {
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5vw;
}

.dropdown {
    margin: 10px;
}

.portfolio-card-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: absolute;
    top: 60%;
    justify-content: space-around;
    width: 100%;
    opacity: .9;
    margin-bottom: 500px;
}

.ui.card.project-card {
    height: auto !important;
    width: 40% !important;
    margin: 20px !important;
    border: 5px solid darkorange;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
    opacity: 0.9;
    border-radius: 20px;
}

.header {
    height: 125px !important;
}

.project-card-front {
    width: 100% !important;
    height: auto !important;
    text-shadow: .5px .5px 1px gray;
}

.ui.image.project-image {
    height: auto !important;
    width: 100% !important;
    max-height: 300px !important; 
    overflow: scroll !important;
    padding: 10px;
    /* padding: 10px !important; */
}

.project-description {
    margin-bottom: 30px !important;
    color: darkolivegreen;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
}

h1.project-name {
    font-family: 'Merriweather', serif;
    height: 30% !important;
    font-size: 3vw;
    text-shadow: 1px 2px 2px #000000;
}

h1.project-name:hover {
    color: darkolivegreen;
}

.ui.orange.label {
    margin-bottom: 5px !important;
    /* top: 85%; */
    box-shadow: 1px 1px 2px #000000 !important;
}

.project-line {
    border: 1px solid gray;
    width: 30%;
    /* margin-top: 10px; */
}