.home {
    position: absolute;
    left: 0;
    top: 30%;
    width: 100%;
    text-align: center;
    z-index: 1;
}

.hero-image-2 {
    background-image: url('../../Assets/Sri-Lanka.jpg');
    height: 850px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    top: 500px;
}

#home-name-first {
    font-size: 10vw;
    font-family: 'Merriweather', serif;
    float: left;
    padding-right: 3vw;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

#home-name-middle {
    font-size: 10vw;
    font-family: 'Merriweather', serif;
    padding-right: 0px;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

#home-name-period {
    font-size: 10vw;
    font-family: 'Merriweather', serif;
    color: darkorange;
    padding-right: 3vw;
    text-shadow: 2px 2px 4px #000000;
}

#home-name-last {
    font-size: 10vw;
    font-family: 'Merriweather', serif;
    float: right;
    padding-right: 50px;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

#home-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 5vw;
    color: darkorange;
    font-style: italic;
    text-shadow: 2px 2px 4px #000000;
}

.home-title-line {
    border: 5px solid white;
    width: 50%;
    margin-top: 250px;
    margin-bottom: 75px;
    box-shadow: 2px 2px 4px #000000;
}

.text-box {
    display: flex;
    color: #fff;
    align-items: center;
    width: 100%;
    float: left;
    padding-left: 50px;
    justify-content: center;
    margin-top: 5px;
}

.bio {
    display: flex;
    top: 85%;
    position: absolute;
    background-color: darkorange;
    padding: 30px;
    align-items: center;
    justify-content: space-evenly;
    vertical-align: middle;
    overflow: hidden;
    border-top: 2px solid white;
    border-bottom: 2px solid white
}

.photo {
    width: 40%;
    height: auto;
    float: right;
    border: 1px solid white;
    box-shadow: 2px 2px 4px #000000;
}

.bio-content {
    float: left;
    width: 45%;
    font-family: 'Montserrat', sans-serif;
    font-size: 2vw;
    text-align: justify;
    color: white;
    height: 100%;
}

.bio-content h2 {
    font-family: 'Merriweather', serif;
    font-size: 3vw;
    text-shadow: 2px 2px 4px #000000;
}

.bio-line {
    border: 2px solid darkolivegreen;
    box-shadow: 2px 2px 4px #000000;
}

.bio-content p {
    text-shadow: 1px 1px 2px #000000;
}

.page-title{
    top: 175%;
    width: 100%;
    font-family: 'Merriweather', serif;
    position: absolute;
    color: white;
    font-size: 8vw;
    text-shadow: 2px 2px 4px #000000;
}

.timeline {
    position: absolute;
    width: 100%;
    align-items: center;
    top: 200%;
    z-index: 1;
    margin-bottom: 500px;
}

.experience-title-line {
    border: 5px solid darkorange;
    width: 30%;
    z-index: 0;
}

.Timeline-line-manual{
    height: 2300px;
    background: gray;
    z-index: -1;
    position: absolute;
    width: 3px;
    left: 0;
    right: 0;
    margin: auto;
    top: -2em;
    box-shadow: 1px 1px 2px #000000;
}

div.content {
    font-family: 'Merriweather', serif;
}

.header {
    font-family: 'Merriweather', serif !important;
    border: none !important;
}

.ui.orange.fluid.raised.card {
    box-shadow: 2px 2px 4px 1px #000000;
}

.ui.purple.fluid.raised.card {
    box-shadow: 2px 2px 4px 1px #000000;
}

.ui.pointing.label::before {
    width: 1.5em !important;
    height: 1.5em !important;
}