.experience-container {
    display: flex;
    justify-content: center;
}

.experience-page-title{
    top: 30%;
    font-family: 'Merriweather', serif;
    position: absolute;
    color: white;
    font-size: 8vw;
    text-shadow: 2px 2px 4px #000000;
}

.experience-page-title-line {
    border: 5px solid darkorange;
    width: 75%;
    box-shadow: 2px 2px 4px #000000 !important;
}

.experience-card-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: absolute;
    top: 45%;
    justify-content: space-around;
    width: 100%;
    opacity: .9;
    margin-bottom: 500px;
}

.ui.card.experience-card {
    margin: 30px !important;
    width: 400px;
    height: 800px;
    border: 5px solid darkolivegreen;
    box-shadow: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); 
    border-radius: 20px;
}

.experience-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: gray;
    color: white;
    height: 250px;
    border-bottom: 6px solid darkolivegreen;
}

.ui.image {
    height: 300px !important;
    width: auto !important;
    padding: 10px !important;
}

h1 {
    margin-top: 10px !important;
    padding-left: 5px;
    padding-right: 5px;
    color: darkorange;
    font-family: 'Merriweather', serif;
    text-shadow: 1px 1px 2px black;
}
h3 {
    margin-top: 10px !important;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: .5px .5px 1px black;

}

h3.title {
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
    margin: 5px;
}

h4 {
    /* margin-top: 10px !important; */
    margin-bottom: 0px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-shadow: .5px .5px 1px black;
}

p{
    color: white;
    margin-bottom: 30px !important;
    text-shadow: .5px .5px 1px black;

}

.work-description {
    margin-right: 20px;
    align-content: stretch;
    max-height: 225px;
    overflow-y: auto;
    text-shadow: .5px .5px 1px gray;

}

li {
    margin-top: 20px;
}

.header {
    border-top: 6px solid darkolivegreen;
    height: 60%!important;
}

.experience-line {
    top: 80%;
    border: 1px solid white;
    width: 30%;
    margin-top: 30px;
}