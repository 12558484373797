body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    background-image: url('./Assets/SL.jpeg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

html {
    height: 100%;
}

body {
    background: transparent !important;
    min-height: 100vh !important;
    margin-bottom: 500px !important;
}

#root {
    text-align: center;
}